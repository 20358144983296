import React, { useState, useEffect } from 'react';
import Header from './header';
import { useLocation } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import  AppStore  from '../assets/appstore.svg';
// import usePageSEO from '../hooks/usePageSEO';


const Trip = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Example: Access a specific query parameter
  const t = queryParams.get('t');
  const n = queryParams.get('n');
  const l = queryParams.get('l');

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (t) {
      const storage = getStorage();
      const imageRef = ref(storage, `trips/${t}.jpg`);

      getDownloadURL(imageRef)
        .then((url) => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.error("Error fetching image:", error.code, error.message);
        });
    }
  }, [t]);

  // usePageSEO({
  //   title: `${n}'s trip to ${l}`,
  //   description: `View ${n}'s trip on Sail Vacations`,
  //   ogTitle: "Sail",
  //   ogDescription: `View ${n}'s trip on Sail Vacations`,
  //   ogImage: imageUrl,
  //   ogUrl: window.location.href
  // });

  return (
    <div className={`trip-container`} style={{marginTop: "200px", width: "100%"}}>
      <Header />
      <p>
        {imageUrl && <img style={{width:'200px', borderRadius: '20px', border:'8px solid #fff', boxShadow:'0px 5px 10px rgba(0, 0, 0, 0.2)', rotate:'7deg', margin: 20}} src={imageUrl} alt={`${n}'s trip`} />}
         <h2>You're missing out.</h2>
      </p>
      <p>
        Download the Sail mobile app for iOS to view <b>{n}'s</b> trip to <b>{l}</b>.
      </p>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <a href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
            <img src={AppStore}  alt="sail_logo" style={{padding:0, background: "transparent",  width: '200px', margin:'0', cursor:"pointer"}} />
        </a>
      </div>
    </div>
  );
};

export default Trip;
