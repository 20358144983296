import React, { useRef, useState, useEffect } from 'react';
import hero_styles from './hero.module.css';
import Toolbar from '../../components/toolbar';
import hero from '../../assets/hero.png';
import AppStore from '../../assets/appstore.svg';
import RotateWord from '../../components/rotate_word';

const Hero = ({ onFeaturesClick, onFirstClassClick }) => {
  const logoDivRef = useRef(null);
  const titleH1Ref = useRef(null);
  const buttonDivRef = useRef(null);
  const [totalHeight, setTotalHeight] = useState(0);

  useEffect(() => {
      const logoDivHeight = logoDivRef.current ? logoDivRef.current.offsetHeight : 0;
      const titleH1Height = titleH1Ref.current ? titleH1Ref.current.offsetHeight : 500;
      const buttonDivHeight = buttonDivRef.current ? buttonDivRef.current.offsetHeight : 0;

      const total = logoDivHeight + titleH1Height + buttonDivHeight;
      setTotalHeight(total);
      console.log('total height', total);
  }, []);
  const words = [
    'Organize your itinerary.', 
    'Collaborate with friends.', 
    'Plan your dream vacations.', 
    'Find your community.', 
    'Explore new destinations.',
  ];

  return (
    <div className={hero_styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', zIndex: 3}}>
          <Toolbar index={true} totalHeight={totalHeight} onFeaturesClick={onFeaturesClick} onFirstClassClick={onFirstClassClick}/>
          <h1 ref={titleH1Ref}  type="title">The Ultimate <br /> Travel Companion</h1>
          
          <a style={{display: 'flex', alignItems: "center",marginTop: "50px", marginBottom: "50px", width:'250px'}} href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
            <img src={AppStore} alt="sail_logo" style={{filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))", margin: '0px', width: "100%"}} />
          </a>
          
          
        <img src={hero}  alt="Sail Hero" style={{opacity: 0}}/>
        <RotateWord phrases={words}/>
        
      </div>
    </div>
  );
};

export default Hero;
