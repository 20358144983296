import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ImageStack from './imagestack';
import feature_styles from './features.module.css';
import Badge from '../../components/badge';
import { HeartFill, Fire } from 'react-bootstrap-icons';
import chair from '../../assets/chair.png';

const Features = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)'
  });
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });

  return (
    <div>
      <div className={feature_styles.feature}>
        <p className={feature_styles.para}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h2 style={{textAlign:"left"}}><b>Craft the perfect itinerary with ease</b></h2></div>
 
            Sail turns travel planning into a social experience, making it easy to bring friends and family together on every adventure.
              <br/><br/>

            With Sail, organizing and sharing travel plans is simple, interactive, and collaborative. <b>Invite companions, coordinate activities, and discover new destinations as a group.</b> Sail redefines travel by making every trip a shared experience.

        </p>
        <ImageStack />
      </div>
     {!isMobile &&

      <div style={{margin: "5em 0 0 0", display: "flex", justifyContent: "center"}}>
        <hr style={{width: "50%", borderBottom: "2px solid #d5d5d5", borderTop: "none", borderLeft: "none", borderRight: "none"}}/>
      </div>

     }
      <div className={feature_styles.feature}>
        {isDesktopOrLaptop && <img src={chair} type="feature_img" alt="man in chair" />}
        <p className={feature_styles.para}>
            <h2 style={{textAlign:"left"}}><b>Avoid vacation planning burnout</b></h2>
            Planning a vacation can be stressful, but it doesn't have to be. <b>Sail takes the stress out of planning your trip</b>, so you can focus on enjoying your vacation.
        </p>
        {isMobile && <img src={chair} type="feature_img" alt="Sail Hero" />}
      </div>
    </div>
  );
};

export default Features;

{/* <Badge word="perfect" color={"#FF6D6D"} IconComponent={HeartFill}/> */}
{/* <Badge word="burnout" color={"#FF7B00"} IconComponent={Fire}/> */}