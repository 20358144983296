import React, { useEffect, useState } from 'react';
import styles from './scrollhero.module.css'; // Import the CSS file for styling
import hero from '../../assets/hero2.png';
import phone from '../../assets/phone.png';

const ScrollHero = () => {
  const isMobile = window.innerWidth <= 768;

  const [width, setWidth] = useState(isMobile ? 300 : 500);
  const [borderRadius, setBorderRadius] = useState(10);
  const [opacity, setOpacity] = useState(0);
  const [opacityFull, setOpacityFull] = useState(1);
//   const [scale, setScale] = useState(1);
  const [translateY, setTranslateY] = useState(isMobile ? 300 : 350);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const baseWidth = isMobile ? 300 : 500;
      const minWidth = isMobile ? 240 : 240;
      const borderRadiusFactor = 0.1;
      const maxBorderRadius = 45;

      setWidth(Math.max(baseWidth - scrollPosition * 0.7, minWidth));
      setBorderRadius(Math.min(scrollPosition * borderRadiusFactor + 10, maxBorderRadius));
      setOpacity(scrollPosition < (isMobile ? 200 : 375) ? 0 : scrollPosition * 0.003);
      
      let translateY;
      if (scrollPosition <= 350) {
        translateY = Math.max(350 - scrollPosition, 0);
      } else if (scrollPosition >= 600) {
        translateY = 600-scrollPosition;
      } else {
        translateY = 0;
      }

      setTranslateY(translateY);

      if (scrollPosition > (isMobile ? 600 : 800)) {
        setOpacityFull((scrollPosition - (isMobile ? 600 : 800)) * -0.8);
      } else {
        setOpacityFull(1);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <div className={styles.scrollHero} style={{
      opacity: opacityFull,
      transition: "opacity 0.3s ease-in-out",
      transform: `translateY(${translateY}px)`
    }}>
      <img 
        style={{width: width, height: "480px", objectFit: "cover", borderRadius: borderRadius}}
        src={hero} 
        alt="Background" 
        className={styles.scrollHeroImg}
      />
      <img 
        style={{ height: "500px", width: "auto", opacity: opacity, transition: "opacity 0.3s ease-in-out"}}
        src={phone} 
        alt="Background" 
        className={styles.scrollHeroImg}
      />
    
    </div>
  );
};

export default ScrollHero;
