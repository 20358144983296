import React from 'react';
import footer_styles from './footer.module.css';
import footer from '../assets/footer.png';
import { Link } from 'react-router-dom';

const Footer = () => {


    return (
      <div>
        <div className={footer_styles.footer_div}>
          <img src={footer} alt="" className={footer_styles.footer_image} />
          
          <div className={footer_styles.footer_bottom_div}>
            <p style={{fontSize: "0.5rem"}}>© 2024 Sail LLC. All rights reserved. </p>
            <div style={{display: "flex"}}>
            <Link to="/contact" className={footer_styles.a} style={{fontSize: "0.5rem", marginRight: "10px"}}>Contact</Link>
            <Link to="/terms-of-service" className={footer_styles.a} style={{fontSize: "0.5rem", marginRight: "10px"}}>Terms of Service</Link>
            <Link to="/privacy-policy" className={footer_styles.a} style={{fontSize: "0.5rem"}}>Privacy Policy</Link>
              {/* <a type='footer' href="/terms-of-service">Terms of Service</a>
              <a type='footer' href="/privacy-policy">Privacy Policy</a> */}
            </div>    
          </div>
        </div>
        
      </div>
    );
};

export default Footer;
