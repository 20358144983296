import React, { forwardRef,useState, useEffect, useMemo } from "react";
import about_styles from "./features_styles.module.css"
import Card from "./card_2"
import sail_itinerary_tilted from "../../assets/itinerary.png"
import network from "../../assets/network.png"
import sail_sign from "../../assets/sail_sign.png"
import sail_maps from "../../assets/sail_maps.png"
import sail_messages from "../../assets/sail_messages.png"
import sail_journal from "../../assets/sail_journal.png"
import weather from "../../assets/weather.png"
import travellers from "../../assets/travellers.png"

const Features = forwardRef((props, ref) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  const xMarginsMobile = useMemo(() => [0, 0, 0, 0, 0, 0], []);
  const xMarginsDesktop = useMemo(() => [200, 200, -100, -200, 50, 50], []);
  const [margins, setMargins] = useState(isMobile ? xMarginsMobile : xMarginsDesktop);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setMargins(isMobile ? xMarginsMobile : xMarginsDesktop);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile, xMarginsMobile, xMarginsDesktop]);

    return (
        <div ref={ref} style={{'paddingTop': 75}}>
      <div className={about_styles.main}>
          <h2>Feature Rich</h2>
          <p>A little of something for everyone. Actually, a lot for everyone.</p>
          <div className={about_styles.content}>
            <Card title={"Top-tier itinerary builder."}  description={"We reimagined what an itinerary builder should be from the ground up. It's now smarter and more intuitive than ever, with every tool you need within reach."} image={sail_itinerary_tilted} isImageBottomAligned={false} />
            <Card title={"Collaborate on trips."}  description={"Easily add your friends to your trip with a single tap. Added travelers can view, edit and add to the itinerary, ensuring everyone is on the same page."} image={travellers} isImageBottomAligned={true}/>
            <Card title={"In-app messaging."}  description={"Sail makes it easy to connect with your travel companions directly in-app, ensuring smooth communication and coordinated plans. Share trips, swap ideas, and keep your trip updated without leaving the app."} image={sail_maps} isImageBottomAligned={true}/>
            <Card title={"Save the trips you love."}  description={"With our trip-saving feature, keep a collection of your favorite travel itineraries all in one place. Save trips that inspire you, revisit past plans, and effortlessly recreate them for future adventures."} image={sail_messages} isImageBottomAligned={true}/>
            <Card title={"Spend smarter."}  description={"Our budget tracker helps you manage trip expenses and stay on top of spending, making group trips more transparent. Easily log costs so you can focus on enjoying your journey."} image={sail_sign} isImageBottomAligned={true}/>
            <Card title={"Pack like a pro."}  description={"Stay organized with our packing list feature, designed to keep track of everything you need for your trip. Collaborate with friends by sharing lists, so no essential items are forgotten. Update and check off items in real-time for stress-free packing."} image={network} isImageBottomAligned={true} />
            <Card title={"Predictive weather."}  description={"See expected conditions for your travel dates, so you can pack and plan activities with confidence."} image={weather} isImageBottomAligned={false}/>
            <Card title={"Share in a snap."} description={"From bios to blogs, Sail trips can be shared across any platform with a simple link."} image={sail_journal} isImageBottomAligned={true}/>
          </div>
      </div>
      </div>
    );
  });
  
  export default Features;