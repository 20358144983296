import React from "react";
import styles from "./detail.module.css";
const Detail = ({detail}) => {
    return (
        <div className={styles.container}>
            
            <h2 style={{lineHeight: "25px", fontSize: "2rem", color: "#b1b1b1"}}>
                {detail[0]}
            </h2>
            
            <p>
                {detail[1]}
            </p>
            <img src={detail[3]} alt="" style={{width: "300px", margin: "auto 0 0 0"}}/>
            
        </div>
    )
}

export default Detail;
