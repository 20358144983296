import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

import Home from './index/index';
import PrivacyPolicy from './auxiliary/privacy_policy';
import TermsOfService from './auxiliary/terms';
import Contact from './auxiliary/contact';
import Trip from './auxiliary/trip';


// const firebaseConfig = {
//   apiKey: "AIzaSyAv4Qw-8-pUZPBoKmqvASuMaZf5p6CnRyc",
//   authDomain: "vacay-project.firebaseapp.com",
//   databaseURL: "https://vacay-project-default-rtdb.firebaseio.com",
//   projectId: "vacay-project",
//   storageBucket: "vacay-project.appspot.com",
//   messagingSenderId: "418510638772",
//   appId: "1:418510638772:web:c16ebfbc02cdf6cb38d1e1",
//   measurementId: "G-N1XKF06J26"
// };


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  // useEffect(() => {
  //   const app = initializeApp(firebaseConfig);
  //   // const analytics = getAnalytics(app);
  // }, []);

  return (
    <Router>
       
      <div className="App">
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/first_class" element={<FirstClass />} />
          <Route path="/features" element={<Features />} />
          <Route path="/security" element={<Security />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact_us" element={<Contact />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/accessibility" element={<Accessibility />} />*/}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/trip" element={<Trip />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
