import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyAv4Qw-8-pUZPBoKmqvASuMaZf5p6CnRyc",
    authDomain: "vacay-project.firebaseapp.com",
    databaseURL: "https://vacay-project-default-rtdb.firebaseio.com",
    projectId: "vacay-project",
    storageBucket: "vacay-project.appspot.com",
    messagingSenderId: "418510638772",
    appId: "1:418510638772:web:c16ebfbc02cdf6cb38d1e1",
    measurementId: "G-N1XKF06J26"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database, app };