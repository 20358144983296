import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import styles from './rotate_word.module.css'; // Import the CSS file


const RotateWord = ({ phrases = [] }) => { // Set default value for phrases
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 1300);

    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <div className={styles.rotateWord}>
        <div className={styles.container}>
        {phrases.map((phrase, index) => {
            let className;
            if (index === currentIndex) {
            className = styles.active;
            } else if (index === (currentIndex + 1) % phrases.length) {
            className = styles.inactivePlusOneIndex;
            } else if (index === (currentIndex + 2) % phrases.length) {
            className = styles.inactivePlusTwoIndex;
            } else if (index === (currentIndex - 1 + phrases.length) % phrases.length) {
            className = styles.inactiveMinusOneIndex;
            } else if (index === (currentIndex - 2 + phrases.length) % phrases.length) {
            className = styles.inactiveMinusTwoIndex;
            } else {
            className = styles.inactive;
            }

            return (
            <div key={index} className={className}>
                {phrase}
            </div>
            );
        })}
        </div>
        <b >All in one app.</b>
    </div>

  );
};

RotateWord.propTypes = {
  phrases: PropTypes.arrayOf(PropTypes.string), // Define prop types
};

export default RotateWord;
