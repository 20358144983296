import first_class from './first_class.module.css';
import React, { useEffect, useRef, forwardRef } from 'react';
import { LightningChargeFill } from 'react-bootstrap-icons';
import Badge from '../../components/badge';
import FirstClassDetails from '../../components/first_class_details';
import sail_first_class_boat from '../../assets/sail_first_class_boat.png';
import laurel_left_gray from '../../assets/laurel_left_gray.svg';
import laurel_right_gray from '../../assets/laurel_right_gray.svg';
import transition_2 from '../../assets/transition_2.svg';
import transition_3 from '../../assets/transition_3.svg';
import sail_iridescent_sticker from '../../assets/sail_iridescent_sticker.png';
import sail_rainbow_sticker from '../../assets/sail_rainbow_sticker.png';
import sail_retro from '../../assets/sail_retro.png';
import sail_blue_background from '../../assets/sail_blue_background.png';
import highlighter from '../../assets/highlighter.png';


const FirstClass = forwardRef((props, ref) => {
    const textRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (textRef.current && imageRef.current) {
            // Set the image width to match the text width
            const textWidth = textRef.current.offsetWidth;
            imageRef.current.style.width = `${textWidth/2}px`;
        }
    }, []);

    return (
        <div className={first_class.content} ref={ref}>
                    <img className={first_class.transitionImage} src={transition_2} alt="sail_logo"/>

            <div className={first_class.first_class_div} style={{"padding-top": "100px","padding-bottom": "100px"}}>
                <div className={first_class.first_class_header}>
                <img src={laurel_left_gray} type="laurel" alt="sail_logo" />
                <h2 ref={textRef} type="title">Sail First Class</h2>
                    <img src={laurel_right_gray} type="laurel" alt="sail_logo" />
                </div>
                <img ref={imageRef} src={highlighter}  alt="sail_logo" type="highlighter"/>
                <div className={first_class.first_class_content}>
                    <div className={first_class.first_class_content_main}>
                    <img src={sail_first_class_boat}  alt="sail_logo" type="first_class_img"/>

                        <h2>Do more, <Badge word="faster" color={"#FFC921"} textColor='black' textStyle={"italic"} IconComponent={LightningChargeFill}/></h2>
                        <p style={{fontSize: "30px","textAlign":"center", lineHeight: "40px", margin: "25px", fontWeight: "350"}}>
                            For our power planners, we offer a variety of tools to help you organize your trips faster and more efficiently. 
                        </p>
                        
                        <FirstClassDetails/>
                    </div>
                    
                </div>
            </div>
            <img className={first_class.transitionImage} src={transition_3}  alt="sail_logo" type="transition2" />
            <div className={first_class.sticker_div}>
                <img src={sail_iridescent_sticker} alt="sail_logo" />
                <img src={sail_rainbow_sticker} alt="sail_logo" />
                <img src={sail_retro} alt="sail_logo" />
                <img src={sail_blue_background} alt="sail_logo" />
            </div>
        </div>
    );
});

export default FirstClass;