import React, { useState, useEffect } from 'react';
import toolbar_styles from './toolbar.module.css';
import logo_black from '../assets/logo_black.svg';
import  AppStore  from '../assets/appstore.svg';

const Toolbar = ({ index = false, totalHeight = -10, onFeaturesClick, onFirstClassClick }) => {
    const [isVisible, setIsVisible] = useState(!index);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (index) {
                if (window.scrollY >= 5 && !isVisible) {
                    setIsVisible(true);
                    setIsAnimatingOut(false);
                } else if (window.scrollY < 5 && isVisible && !isAnimatingOut) {
                    setIsAnimatingOut(true);
                    setTimeout(() => setIsVisible(false), 30);
                }
            }
        };

        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, [isVisible, isAnimatingOut, totalHeight, index]);

    if (!isVisible && !isAnimatingOut) {
        return null;
    }

    const toolbarClass = `${toolbar_styles.toolbar_div_light} ${isVisible ? toolbar_styles.expanded : ''} ${isAnimatingOut ? toolbar_styles.animate_out : ''} ${!index ? toolbar_styles.no_animation : ''}`;
    return (
        <div className={toolbarClass} style={{minHeight: '30px'}}>
            <div style={{display: 'flex', flexDirection:'row', alignContent:'center', justifyContent:"space-between", width:'100%'}}>
                <div style={{display: 'flex', flexDirection:'row', alignContent:'center', justifyContent:"center"}}>
                <a type='logo' href="/" style={{padding:'0px', margin:"auto 0", display:"flex", flexDirection:"row", alignContent:"center", justifyContent:"center"}}> 
                    <img src={logo_black} style={{ height: '30px', width: '30px', margin:"0 0 0 10px" }} alt="sail_logo" />
                    <h1 style={{margin: 0, fontSize: '20px'}}>SAIL</h1>
                </a>
                
                </div>
                <button type='text' className="" onMouseDown={onFeaturesClick}>
                <h3 style={{margin: "0", fontSize: "12pt",padding:"0px"}}>
                        Features
                    </h3>
                </button>
                <button type='text' 
                    className=""
                    onMouseDown={onFirstClassClick}
                    ><svg className={toolbar_styles.laurel} style={{margin:"0 3px 0 0"}} width="13" height="26" viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.24716 23.5714C7.56748 23.5128 6.72373 23.7003 6.14951 24.0519C5.90341 24.1925 5.86826 
                        24.4152 6.0792 24.6027C6.60654 25.0831 7.41513 25.4464 8.10654 25.4933C8.82138 25.587 9.67685
                        25.3644 10.2511 24.9308C10.4386 24.8019 10.4503 24.6027 10.2745 24.4386C9.75888 23.9581 8.95029
                        23.6183 8.24716 23.5714ZM10.7081 19.6105C10.3331 20.1847 10.1222 21.0284 10.1456 21.7081C10.1925
                        22.423 10.5206 23.2433 10.9776 23.7472C11.13 23.923 11.3175 23.923 11.4698 23.7355C11.9269 23.1964
                        12.1729 22.3409 12.1026 21.6144C12.0558 20.9347 11.7159 20.1261 11.2472 19.587C11.0714 19.3761
                        10.8604 19.3878 10.7081 19.6105ZM4.94248 20.3956C4.29795 20.1613 3.41904 20.1378 2.76279 20.337C2.50498
                        20.4191 2.43466 20.6183 2.57529 20.8527C2.96201 21.4503 3.65341 22.0011 4.29795 22.2355C4.9542 22.505
                        5.84482 22.505 6.51279 22.2238C6.73545 22.1417 6.79404 21.9659 6.66513 21.755C6.29013 21.1573 5.59873
                        20.6183 4.94248 20.3956ZM8.31748 17.2081C7.81357 17.6652 7.37998 18.4152 7.25107 19.0831C7.09873 19.7863
                        7.2042 20.6652 7.5206 21.2628C7.61435 21.4855 7.80185 21.5206 8.00107 21.38C8.56357 20.9698 9.0206 20.2081
                        9.13779 19.4816C9.27841 18.8136 9.16123 17.9347 8.84482 17.3019C8.73935 17.0441 8.5167 17.0089 8.31748
                        17.2081ZM0.00888283 15.8605C0.102633 16.5402 0.512789 17.337 1.0167 17.8292C1.50888 18.3448 2.31748 18.6964
                        3.04404 18.7198C3.27841 18.7316 3.40732 18.5792 3.37216 18.3448C3.25498 17.63 2.84482 16.8683 2.34091
                        16.4113C1.83701 15.9308 1.06357 15.5558 0.383883 15.4738C0.102633 15.4386 -0.0379922 15.5909 0.00888283
                        15.8605ZM6.72373 14.8527C6.0792 15.0753 5.38779 15.5909 4.98935 16.1417C4.56748 16.6925 4.32138 17.5245
                        4.34482 18.2511C4.36826 18.4738 4.5206 18.5909 4.75498 18.5558C5.4581 18.3917 6.19638 17.8878 6.57138
                        17.2784C6.9581 16.6808 7.21591 15.8488 7.16904 15.1573C7.16904 14.8761 6.99326 14.7589 6.72373 14.8527ZM4.7667
                        12.1105C4.21591 12.5558 3.75888 13.3058 3.61826 13.9972C3.5831 14.2081 3.70029 14.3722 3.92295 14.3839C4.64951
                        14.4073 5.48154 14.0909 6.00888 13.6222C6.53623 13.1652 7.00498 12.4034 7.15732 11.7238C7.19248 11.4659
                        7.05185 11.2902 6.79404 11.3019C6.09091 11.3605 5.28232 11.6652 4.7667 12.1105ZM0.0206016 10.5753C-0.0497109
                        11.2667 0.137789 12.1222 0.512789 12.7316C0.864352 13.3644 1.55576 13.9152 2.23545 14.1027C2.44638 14.173
                        2.63388 14.0558 2.65732 13.8214C2.72763 13.1534 2.52841 12.298 2.15341 11.6886C1.77841 11.1027 1.11045
                        10.5402 0.489352 10.2941C0.243258 10.1886 0.0440391 10.2941 0.0206016 10.5753ZM5.41123 8.17297C4.74326
                        8.40735 4.05185 8.93469 3.67685 9.54407C3.54795 9.73157 3.61826 9.93078 3.8292 10.0245C4.49716 10.2941
                        5.37607 10.2941 6.04404 10.0245C6.72373 9.76672 7.42685 9.20422 7.7667 8.60657C7.90732 8.39563 7.83701
                        8.19641 7.5792 8.11438C6.91123 7.92688 6.04404 7.9386 5.41123 8.17297ZM1.47373 5.10266C1.16904 5.74719
                        1.05185 6.61438 1.18076 7.29407C1.30966 8.00891 1.7667 8.75891 2.35263 9.18078C2.52841 9.32141 2.71591
                        9.26282 2.8331 9.05188C3.13779 8.4425 3.24326 7.57532 3.09091 6.88391C2.95029 6.20422 2.50498 5.45422 2.01279
                        4.98547C1.82529 4.82141 1.60263 4.86828 1.47373 5.10266ZM7.53232 4.79797C6.84091 4.92688 6.0792 5.37219
                        5.6456 5.91125C5.50498 6.07532 5.54013 6.27453 5.72763 6.40344C6.34873 6.76672 7.22763 6.88391 7.91904
                        6.69641C8.61045 6.54407 9.37216 6.08703 9.81748 5.57141C9.99326 5.36047 9.94638 5.14953 9.70029 5.04407C9.0792
                        4.76282 8.22373 4.65735 7.53232 4.79797ZM4.07529 1.1886C3.67685 1.78625 3.44248 2.64172 3.48935 3.32141C3.50107
                        4.03625 3.85263 4.84485 4.36826 5.36047C4.54404 5.52453 4.74326 5.5011 4.87216 5.3136C5.25888 4.71594 5.49326
                        3.86047 5.43466 3.18078C5.38779 2.51282 5.07138 1.71594 4.62607 1.16516C4.43857 0.954222 4.22763 0.97766 4.07529
                        1.1886ZM10.1222 0.00500337C9.44248 0.110472 8.66904 0.520628 8.17685 1.0011C7.68466 1.48157 7.29795 2.29016
                        7.21591 2.96985C7.19248 3.20422 7.32138 3.34485 7.55576 3.33313C8.2706 3.29797 9.06748 2.92297 9.55966
                        2.37219C10.0284 1.88 10.4151 1.09485 10.5206 0.391722C10.544 0.122191 10.4034 -0.0301529 10.1222 0.00500337Z"
                        fill={"#000000"} fillOpacity="0.85">
                    </path>
                    </svg>
                    <h3 style={{margin: "0", fontSize: "12pt",padding:"0px"}}>
                        First Class
                    </h3>
                    <svg className={toolbar_styles.laurel} style={{margin:"0 0 0 3px"}} width="13" height="26" viewBox="0 0 13 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.86734 23.5714C3.16421 23.6183 2.35562 23.9581 1.83999 24.4386C1.66421 24.6027 1.67593 24.8019 
                            1.86343 24.9308C2.43765 25.3644 3.29312 25.587 4.00796 25.4933C4.68765 25.4464 5.49624 25.0831 6.03531
                            24.6027C6.24624 24.4152 6.21109 24.1925 5.96499 24.0519C5.39077 23.7003 4.54702 23.5128 3.86734 23.5714ZM1.4064
                            19.6105C1.25406 19.3878 1.04312 19.3761 0.867336 19.587C0.398586 20.1261 0.0587426 20.9347 0.0118676 21.6144C
                            -0.0584449 22.3409 0.187649 23.1964 0.64468 23.7355C0.797024 23.923 0.984524 23.923 1.13687 23.7472C1.5939
                            23.2433 1.92202 22.423 1.95718 21.7081C1.99234 21.0284 1.76968 20.1847 1.4064 19.6105ZM7.17202 20.3956C6.51577
                            20.6183 5.82437 21.1573 5.44937 21.755C5.32046 21.9659 5.37906 22.1417 5.60171 22.2238C6.26968 22.505 7.16031
                            22.505 7.81656 22.2355C8.46109 22.0011 9.15249 21.4503 9.53921 20.8527C9.67984 20.6183 9.60952 20.4191 9.35171
                            20.337C8.69546 20.1378 7.81656 20.1613 7.17202 20.3956ZM3.79702 17.2081C3.58609 17.0089 3.37515 17.0441 3.26968
                            17.3019C2.95327 17.9347 2.83609 18.8136 2.96499 19.4816C3.0939 20.2081 3.55093 20.9698 4.11343 21.38C4.30093
                            21.5206 4.50015 21.4855 4.5939 21.2628C4.91031 20.6652 5.00406 19.7863 4.86343 19.0831C4.72281 18.4152 4.30093
                            17.6652 3.79702 17.2081ZM12.1056 15.8605C12.1525 15.5909 12.0119 15.4386 11.7306 15.4738C11.0509 15.5558 10.2775
                            15.9308 9.77359 16.4113C9.26968 16.8683 8.85952 17.63 8.74234 18.3448C8.70718 18.5792 8.83609 18.7316 9.07046
                            18.7198C9.79702 18.6964 10.6056 18.3448 11.0978 17.8292C11.6017 17.337 12.0119 16.5402 12.1056 15.8605ZM5.37906 
                            14.8527C5.12124 14.7589 4.94546 14.8761 4.94546 15.1573C4.89859 15.8488 5.14468 16.6808 5.54312 17.2784C5.91812
                            17.8878 6.6564 18.3917 7.35952 18.5558C7.58218 18.5909 7.74624 18.4738 7.75796 18.2511C7.79312 17.5245 7.53531
                            16.6925 7.12515 16.1417C6.72671 15.5909 6.03531 15.0753 5.37906 14.8527ZM7.33609 12.1105C6.83218 11.6652 6.02359
                            11.3605 5.32046 11.3019C5.05093 11.2902 4.92202 11.4659 4.95718 11.7238C5.10952 12.4034 5.56656 13.1652 6.10562
                            13.6222C6.62124 14.0909 7.46499 14.4073 8.19156 14.3839C8.40249 14.3722 8.5314 14.2081 8.49624 13.9972C8.35562
                            13.3058 7.89859 12.5558 7.33609 12.1105ZM12.0939 10.5753C12.0587 10.2941 11.8712 10.1886 11.6251 10.2941C10.9923
                            10.5402 10.3244 11.1027 9.96109 11.6886C9.58609 12.298 9.37515 13.1534 9.45718 13.8214C9.48062 14.0558 9.6564
                            14.173 9.87906 14.1027C10.5587 13.9152 11.2501 13.3644 11.6017 12.7316C11.9767 12.1222 12.1642 11.2667 12.0939
                            10.5753ZM6.70327 8.17297C6.07046 7.9386 5.20327 7.92688 4.53531 8.11438C4.27749 8.19641 4.20718 8.39563 4.33609
                            8.60657C4.68765 9.20422 5.37906 9.76672 6.07046 10.0245C6.72671 10.2941 7.61734 10.2941 8.28531 10.0245C8.49624
                            9.93078 8.55484 9.73157 8.43765 9.54407C8.06265 8.93469 7.37124 8.40735 6.70327 8.17297ZM10.6291 5.10266C10.5119
                            4.86828 10.2892 4.82141 10.09 4.98547C9.60952 5.45422 9.16421 6.20422 9.02359 6.88391C8.87124 7.57532 8.96499
                            8.4425 9.2814 9.05188C9.38687 9.26282 9.58609 9.32141 9.76187 9.18078C10.3361 8.75891 10.7931 8.00891 10.922
                            7.29407C11.0626 6.61438 10.9455 5.74719 10.6291 5.10266ZM4.57046 4.79797C3.89077 4.65735 3.03531 4.76282 2.41421
                            5.04407C2.16812 5.14953 2.10952 5.36047 2.28531 5.57141C2.74234 6.08703 3.50406 6.54407 4.18374 6.69641C4.88687
                            6.88391 5.76577 6.76672 6.37515 6.40344C6.57437 6.27453 6.60952 6.07532 6.4689 5.91125C6.02359 5.37219 5.27359
                            4.92688 4.57046 4.79797ZM8.03921 1.1886C7.88687 0.97766 7.66421 0.954222 7.48843 1.16516C7.04312 1.71594 6.71499
                            2.51282 6.67984 3.18078C6.60952 3.86047 6.8439 4.71594 7.24234 5.3136C7.37124 5.5011 7.57046 5.52453 7.74624
                            5.36047C8.26187 4.84485 8.61343 4.03625 8.62515 3.32141C8.67202 2.64172 8.43765 1.78625 8.03921 1.1886ZM1.99234
                            0.00500337C1.71109 -0.0301529 1.57046 0.122191 1.5939 0.391722C1.69937 1.09485 2.08609 1.88 2.55484 2.37219C3.04702
                            2.92297 3.8439 3.29797 4.55874 3.33313C4.79312 3.34485 4.92202 3.20422 4.89859 2.96985C4.81656 2.29016 4.42984
                            1.48157 3.92593 1.0011C3.44546 0.520628 2.67202 0.110472 1.99234 0.00500337Z"
                            fill={"#000000"} fillOpacity="0.85">
                        </path>
                    </svg>
                </button>
                
               
                    <div style={{padding:0, margin:"0 15px 0 0", background:"transparent", border: "none", display:"flex"}}>
                        <div style={{ position: 'relative', display: 'flex' }}>
                            <a href="https://apps.apple.com/us/app/sail-vacations-social-travel/id6471536080?ign-itscg=30200&ign-itsct=apps_box_link&mttnsubad=6471536080">
                                <img src={AppStore}  alt="sail_logo" style={{padding:0, background: "transparent",  width: '100px', margin:'0', cursor:"pointer"}} />
                            </a>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default Toolbar;